/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Container } from 'src/components/core/layout/container';
import { HeaderVideo } from 'src/components/layout/homepage/header/video';
import { Lead, Title } from 'src/components/layout/homepage/styles';
import { animatedBlob, animatedCircle } from 'src/styles/keyframes';
import { ifProp } from 'styled-tools';
import { media } from 'src/styles/utils/media';
import { transparentize } from 'src/styles/utils/colors';
import buttonSvg from 'src/assets/svg/button.svg';
import styled from 'styled-components';

/**
 * `backgrounds` constant.
 */

const backgrounds = {
  circle:
    'radial-gradient(circle at 0 0, var(--color-gray400) 20%, var(--color-blue500) var(--home-header-circle-gradient-end))',
  mask: `linear-gradient(to bottom, ${transparentize(
    'white',
    0
  )}, var(--color-gray200))`,
  notVideo: `linear-gradient(180deg, ${transparentize(
    'white',
    0
  )} 0%, var(--color-gray200) 100%)`,
  separator: `linear-gradient(180deg, ${transparentize(
    'gray200',
    0
  )} 0.52%, var(--color-gray200) 52.08%, ${transparentize('gray200', 0)} 100%)`,
  video: `linear-gradient(180deg, ${transparentize(
    'white',
    0
  )} 30%, var(--color-gray200) 60%)`,
  wrapper: 'linear-gradient(90deg, var(--color-sage500), var(--color-sage400))'
};

/**
 * `Props` type.
 */

type Props = {
  buttonLabel: string;
  buttonUrl: string;
  className?: string;
  fullVideoUrl?: string;
  lead: string;
  previewVideoUrl?: string;
  title: string;
  videoImage?: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.section`
  background: ${backgrounds.wrapper};
  color: var(--color-white);
  overflow-x: clip;
  overflow-y: hidden;
  position: relative;

  &::before {
    background: ${backgrounds.wrapper};
    content: '';
    inset: 0;
    mask-image: ${backgrounds.mask};
    position: absolute;
  }
`;

/**
 * `Blob` styled component.
 */

const Blob = styled.div`
  animation: ${animatedBlob} 20s ease-in-out infinite;
  background-color: var(--color-blue500);
  border-radius: 33% 67% 66% 34% / 50% 65% 35% 50%;
  box-shadow: var(--shadow-blur);
  height: 80vw;
  left: -8vw;
  opacity: 0.7;
  overflow: hidden;
  position: absolute;
  top: 60%;
  width: 120vw;

  ${media.min.md`
    height: 65vw;
    width: 92vw;
  `}
`;

/**
 * `Circle` styled component.
 */

const Circle = styled.div`
  --home-header-circle-gradient-end: 90%;
  --home-header-circle-size: min(392px, 105vw);

  animation: ${animatedCircle} 15s ease infinite;
  background-image: ${backgrounds.circle};
  background-position: center center;
  background-size: 150% 150%;
  border-radius: 50%;
  height: var(--home-header-circle-size);
  opacity: 0.6;
  pointer-events: none;
  position: absolute;
  right: -33%;
  top: 23%;
  width: var(--home-header-circle-size);

  ${media.min.sm`
    --home-header-circle-gradient-end: 70%;
    --home-header-circle-size: min(630px, 41vw);

    opacity: 1;
    right: 7%;
    top: 30%;
  `}

  ${media.min.ms`
    top: 22%;
  `}
`;

/**
 * `Column` styled component.
 */

const Column = styled.div`
  padding: min(270px, 20vh) 0 var(--vertical-spacing);
  position: relative;

  ${media.min.sm`
    padding: min(320px, 30vh) 0;
    width: 60%;
  `}
`;

/**
 * `VideoSection` styled component.
 */

const VideoSection = styled.div<{ hasVideo: boolean }>`
  position: relative;

  ${media.min.sm`
    padding: var(--vertical-spacing) 0 0;
  `}

  ${ifProp(
    'hasVideo',
    `
    background: ${backgrounds.video};
  `,
    `
    background: ${backgrounds.notVideo};

    ${media.min.sm`
      min-height: min(320px, 30vh);
    `}
  `
  )}
`;

/**
 * `Separator` styled component.
 */

const Separator = styled.div`
  background: ${backgrounds.separator};
  bottom: -15%;
  height: 30%;
  left: 50%;
  position: absolute;
  width: 1px;
`;

/**
 * `Header` component.
 */

export function Header(props: Props) {
  const {
    buttonLabel,
    buttonUrl,
    fullVideoUrl,
    lead,
    previewVideoUrl,
    title,
    videoImage,
    ...rest
  } = props;

  return (
    <Wrapper {...rest}>
      <Container>
        <Blob />

        <Circle />

        {!!videoImage && <Separator />}

        <Column>
          <Title
            as={'h2'}
            data-scroll={'fade-in-up'}
            data-scroll-animation-delay={200}
          >
            {lead}
          </Title>

          <Lead
            as={'h1'}
            data-scroll={'fade-in-up'}
            data-scroll-animation-delay={400}
          >
            {title}
          </Lead>

          <Button
            colorTheme={'whiteOutlined'}
            data-scroll={'fade-in-up'}
            data-scroll-animation-delay={600}
            href={buttonUrl}
            icon={buttonSvg}
          >
            {buttonLabel}
          </Button>
        </Column>
      </Container>

      <VideoSection hasVideo={!!videoImage}>
        {!!videoImage && (
          <Container data-scroll={'fade-in-up'}>
            <HeaderVideo
              fullVideoUrl={fullVideoUrl}
              previewVideoUrl={previewVideoUrl}
              videoImage={videoImage}
            />
          </Container>
        )}
      </VideoSection>
    </Wrapper>
  );
}
