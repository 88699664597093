/**
 * Module dependencies.
 */

import { Fill } from '@untile/react-components';
import { ModalPortal } from './portal';
import { RoundButton } from 'src/components/core/buttons/round-button';
import { Svg } from 'src/components/core/svg';
import { fadeInUp, fadeOutDown } from 'src/styles/keyframes';
import { ifProp } from 'styled-tools';
import { media } from 'src/styles/utils/media';
import React, { ReactNode } from 'react';
import closeSvg from 'src/assets/svg/close.svg';
import logoSvg from 'src/assets/svg/logo.svg';
import styled, { css } from 'styled-components';

/**
 * Export `ModalProps` types.
 */

export type ModalProps = {
  children?: ReactNode;
  className?: string;
  isOpen?: boolean;
  onRequestClose: () => void;
  theme?: 'dark' | 'light';
};

/**
 * `Underlay` styled component.
 */

const Underlay = styled(Fill)<Pick<ModalProps, 'isOpen'>>`
  --modal-underlay-color: ${ifProp(
    { theme: 'light' },
    'rgba(255, 255, 255, 0.88)',
    'var(--color-black)'
  )};

  align-items: center;
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  background-color: var(--modal-underlay-color);
  display: flex;
  justify-content: center;
  position: fixed;
  scroll-snap-type: y mandatory;
  z-index: var(--z-index-modal-underlay);

  ${ifProp(
    'isOpen',
    css`
      animation-name: ${fadeInUp};
    `,
    css`
      animation-name: ${fadeOutDown};
      opacity: 0;
      pointer-events: none;
    `
  )}
`;

/**
 * `ModalBody` styled component.
 */

const ModalBody = styled.div<Pick<ModalProps, 'isOpen'>>`
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  color: var(--color-${ifProp({ theme: 'light' }, 'gray700', 'black')});
  height: 100%;
  overflow: hidden;
  padding: 10vmax 1rem 1rem;
  position: relative;
  width: 100%;

  ${media.min.md`
    padding: 10vmax 5vmax 5vmax;
  `}

  ${ifProp(
    'isOpen',
    css`
      animation-name: ${fadeInUp};
    `,
    css`
      animation-name: ${fadeOutDown};
      opacity: 0;
      pointer-events: none;
    `
  )}
`;

/**
 * `Logo` styled component.
 */

const Logo = styled(Svg).attrs({
  icon: logoSvg,
  size: '15vmax'
})`
  left: 1rem;
  position: absolute;
  top: 1rem;

  ${media.min.sm`
    left: 2.5rem;
    top: 2.5rem;
  `}
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled(RoundButton).attrs({
  icon: closeSvg
})`
  position: absolute;
  right: 1rem;
  top: 1rem;

  ${media.min.sm`
    right: 2.5rem;
    top: 2.5rem;
  `}
`;

/**
 * Export `Modal` component.
 */

export function Modal(props: ModalProps) {
  const { children, className, isOpen, onRequestClose, theme } = props;

  return (
    <ModalPortal
      isOpen={!!isOpen}
      preventBodyScroll={!!isOpen}
    >
      <Underlay
        isOpen={!!isOpen}
        theme={theme ?? 'dark'}
      >
        <ModalBody
          className={className}
          isOpen={!!isOpen}
          role={'dialog'}
          theme={theme ?? 'dark'}
        >
          <Logo />

          <CloseButton onClick={onRequestClose} />

          {children}
        </ModalBody>
      </Underlay>
    </ModalPortal>
  );
}
