/**
 * Module dependencies.
 */

import { CSSTransition } from 'react-transition-group';
import { ReactNode } from 'react';
import { createReactPortal } from '@untile/react-components/dist/utils';
import { useBodyScroll } from '@untile/react-components/dist/hooks';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  isOpen: boolean;
  preventBodyScroll: boolean;
};

/**
 * Export `ModalPortal` component.
 */

export function ModalPortal(props: Props) {
  const { children, isOpen, preventBodyScroll } = props;

  useBodyScroll({ off: preventBodyScroll });

  return createReactPortal(
    <CSSTransition
      in={isOpen}
      mountOnEnter
      timeout={300}
      unmountOnExit
    >
      {children}
    </CSSTransition>,
    'modal-root'
  );
}
