/**
 * Module dependencies.
 */

import { Image } from 'src/components/core/image';
import { Modal } from 'src/components/core/modal';
import { RoundButton } from 'src/components/core/buttons/round-button';
import { getFileUriExtension } from 'src/core/utils/url';
import { ifProp } from 'styled-tools';
import { useCallback, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import playSvg from 'src/assets/svg/play.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  fullVideoUrl?: string;
  previewVideoUrl?: string;
  videoImage: string;
};

/**
 * `VideoWrapper` styled component.
 */

const VideoWrapper = styled.div<{ videoPreview: boolean }>`
  padding-top: 65%;
  position: relative;

  ${ifProp(
    'videoPreview',
    `
    &::after {
      background: linear-gradient(180deg, var(--color-sage500) 0%, transparent 100%);
      content: '';
      inset: 0;
      opacity: 0.5;
      pointer-events: none;
      position: absolute;
      transition: opacity var(--transition-default);
    }
  `
  )}

  ${ifProp(
    'onClick',
    `
    cursor: pointer;

    &:focus,
    &:hover,
    &:focus-within {
      &::after {
        opacity: 0.3;
      }
    }
  `
  )}
`;

/**
 * `Video` styled component.
 */

const Video = styled.video`
  height: 100%;
  inset: 0;
  object-fit: cover;
  position: absolute;
  width: 100%;
`;

/**
 * `StyledRoundButton` styled component.
 */

const StyledRoundButton = styled(RoundButton)<{ visible: boolean }>`
  left: 50%;
  opacity: ${ifProp('visible', 1, 0)};
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

/**
 * `HeaderVideo` component.
 */

export function HeaderVideo(props: Props) {
  const { fullVideoUrl, previewVideoUrl, videoImage, ...rest } = props;
  const [isVideoModalOpen, setVideoModalOpen] = useState<boolean>(false);
  const fileExtension = getFileUriExtension(previewVideoUrl) ?? 'mp4';
  const handleOpenVideo = useCallback(() => {
    if (fullVideoUrl && ReactPlayer.canPlay(fullVideoUrl)) {
      setVideoModalOpen(true);
    }
  }, [fullVideoUrl]);

  return (
    <>
      <VideoWrapper
        {...rest}
        {...(fullVideoUrl && {
          handleOpenVideo,
          role: 'button',
          tabIndex: 0
        })}
        videoPreview={!!previewVideoUrl}
      >
        {!previewVideoUrl ? (
          <Image
            alt={'Cover image'}
            fill
            objectFit={'cover'}
            src={videoImage}
          />
        ) : (
          <>
            <Video
              autoPlay={!!previewVideoUrl}
              controls={false}
              loop
              muted
              poster={(!previewVideoUrl && videoImage) || undefined}
            >
              {previewVideoUrl && (
                <source
                  src={previewVideoUrl}
                  type={fileExtension ? `video/${fileExtension}` : undefined}
                />
              )}

              {'Your browser does not support the video tag.'}
            </Video>

            {fullVideoUrl && (
              <StyledRoundButton
                aria-label={'Play video'}
                colorTheme={'white'}
                icon={playSvg}
                onClick={handleOpenVideo}
                visible={!isVideoModalOpen}
              />
            )}
          </>
        )}
      </VideoWrapper>

      {fullVideoUrl && (
        <Modal
          isOpen={isVideoModalOpen}
          onRequestClose={() => {
            setVideoModalOpen(false);
          }}
        >
          <ReactPlayer
            height={'100%'}
            url={fullVideoUrl}
            width={'100%'}
          />
        </Modal>
      )}
    </>
  );
}
